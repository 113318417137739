import { Suspense } from 'react'

import { QueryClientProvider } from '@tanstack/react-query'
import { Provider } from 'jotai'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { Toaster } from 'sonner'

import { LoadingSpinner } from '@/components/LoadingSpinner/LoadingSpinner'
import { TooltipProvider } from '@/components/ui/tooltip'

import { AuthProvider } from './lib/auth/AuthContext'
import { queryClient } from './lib/react-query/ReactQueryClient'
import { routes } from './routes'
import { globalStore } from './store'
import './styles/global.css'

function App() {
  return (
    <Provider store={globalStore}>
      <TooltipProvider>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Suspense fallback={<LoadingSpinner />}>
              <RouterProvider router={createBrowserRouter(routes)} />
            </Suspense>
            <Toaster richColors />
          </AuthProvider>
        </QueryClientProvider>
      </TooltipProvider>
    </Provider>
  )
}

export default App
