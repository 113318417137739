import { atom } from 'jotai'
import Cookies from 'js-cookie'

import { queryClient } from '@/lib/react-query/ReactQueryClient'
import { globalStore } from '@/store'

// src/utils/auth.ts
export type Token = {
  session_id: string
  access_token: string
  access_token_expired_at: string
  refresh_token: string
  refresh_token_expired_at: string
}
export type SessionAuth = Token & {
  user: User
}
export type User = {
  nick_name: string
  email: string
  contract_type: 'FULLTIME' | 'PARTTIME'
  is_active: boolean
  is_admin: boolean
}

export const isAuthenticated = atom(false)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const session = atom<SessionAuth | any>({})

export const P2M_SESSION_KEY = 'p2m_session'

export const auth = {
  getUser: () => globalStore.get(session).user,
  setSession: (data: SessionAuth): void => {
    globalStore.set(session, data)
    globalStore.set(isAuthenticated, true)
    Cookies.set(P2M_SESSION_KEY, JSON.stringify(data), {
      expires: new Date(data.refresh_token_expired_at),
      path: '',
    })
  },
  getSession: () => globalStore.get(session),
  clearSession: () => {
    globalStore.set(session, {})
    globalStore.set(isAuthenticated, false)
    Cookies.remove(P2M_SESSION_KEY, { path: '' })
  },
  checkExpried: (expried_at?: string) => {
    if (expried_at && new Date(expried_at).getTime() > Date.now()) {
      return false
    }
    return true
  },
  verifyTokenId: (expried_at?: string) => {
    return !auth.checkExpried(expried_at)
  },
  getAccessToken: () => globalStore.get(session).access_token,
  getRefreshToken: () => globalStore.get(session).refresh_token,
  logout: (): void => {
    queryClient.clear()
    auth.clearSession()
  },
  isAuthenticated: () => globalStore.get(isAuthenticated),
  getSessionCookie: () => {
    const sessionCookieData = Cookies.get(P2M_SESSION_KEY)
    if (sessionCookieData) {
      const sessionData = JSON.parse(sessionCookieData)
      return sessionData
    }
    return null
  },
}

export type Auth = typeof auth
