import { cn } from '@/lib/utils'
import { stringAvatar, stringToColor } from '@/utils/color'

import { Avatar, AvatarFallback } from './avatar'
import { Tooltip, TooltipContent, TooltipTrigger } from './tooltip'

interface AvatarWithTooltipProps {
  name?: string
  className?: string
  onAvatarClick?: () => void
}

export function AvatarWithTooltip({
  name,
  className,
  onAvatarClick,
  ...rest
}: AvatarWithTooltipProps) {
  if (!name) {
    return null
  }
  return (
    <Tooltip key={name} delayDuration={100}>
      <TooltipTrigger>
        <Avatar
          className={cn(
            'relative z-1 w-8 h-8 border text-sm border-white cursor-pointer outline-none hover:z-20',
            className,
          )}
          onClick={onAvatarClick}
          {...rest}
        >
          <AvatarFallback
            className="text-white uppercase"
            style={{ background: stringToColor(name) }}
          >
            {stringAvatar(name)}
          </AvatarFallback>
        </Avatar>
      </TooltipTrigger>
      <TooltipContent side="bottom">
        <p>{name}</p>
      </TooltipContent>
    </Tooltip>
  )
}
