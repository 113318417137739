export const REFRESH_TOKEN_KEY = 'refresh_token_rqk'

export const CREATE_MEMBER_KEY = 'create_member_rqk'
export const LIST_MEMBER_KEY = 'list_member_rqk'
export const DELETE_MEMBER_KEY = 'delete_member_rqk'

export const CREATE_CLIENT_KEY = 'create_client_rqk'
export const LIST_CLIENT_KEY = 'list_client_rqk'
export const DELETE_CLIENT_KEY = 'delete_client_rqk'
export const DETAIL_CLIENT_KEY = 'detail_client_rqk'

export const CREATE_TICKET_KEY = 'create_ticket_rqk'
export const LIST_TICKET_KEY = 'tickets_rqk'
export const UPDATE_TICKET_KEY = 'update_ticket_mution_rqk'
export const DETAIL_TICKET_KEY = 'tickets_detail_rqk'

export const LIST_HISTORIES_KEY = 'histories_ticket_rqk'

export const LIST_LINKS_KEY = 'links_ticket_rqk'

export const LIST_COMMENTS_KEY = 'comments_ticket_rqk'
