import ky, { HTTPError, KyRequest } from 'ky'
import { redirect } from 'react-router-dom'

import { generateRequestId } from '@/utils/id'

import { auth } from '../auth/auth'
import { refreshToken } from '../auth/refresh-token-service'

async function setAuthorizationHeader(request: KyRequest) {
  const session = auth.getSession()
  if (!session) {
    console.warn('No session found')
    return
  }

  let access_token = session.access_token
  const access_token_expired_at = session.access_token_expired_at

  if (auth.checkExpried(access_token_expired_at)) {
    const refresh_token = auth.getRefreshToken()
    if (!refresh_token) {
      console.error('Refresh token not found')
      return
    }

    try {
      const new_token = await refreshToken(refresh_token)
      access_token = new_token.access_token
    } catch (error) {
      console.error('Failed to refresh token:', error)
      return
    }
  }

  if (access_token) {
    request.headers.set('Authorization', `Bearer ${access_token}`)
  }
}

const api = ky.create({
  prefixUrl: import.meta.env.VITE_BACKEND_SERVICE,
  hooks: {
    beforeRequest: [
      async (request) => {
        request.headers.set('X-Request-Id', generateRequestId())
        await setAuthorizationHeader(request)
      },
    ],
    afterResponse: [
      async (_request, _options, response) => {
        if (response.status === 401) {
          // Token might be expired. You could implement token refresh logic here.
          // For now, we'll just clear the token
          auth.clearSession()
          redirect('/login')
          // You might want to redirect to login page or trigger a refresh token flow here
        }
        return response
      },
    ],
  },
  timeout: 20000, // 20 seconds
  retry: 0,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function parseResponse(response: Response): Promise<any> {
  const contentType = response.headers.get('Content-Type')
  if (contentType?.includes('application/json')) {
    return response.json()
  } else {
    return response.text()
  }
}

async function handleResponse<T>(promise: Promise<Response>): Promise<T> {
  try {
    const response = await promise
    return (await parseResponse(response)) as T
  } catch (e) {
    if (e instanceof HTTPError) {
      const errorData = await parseResponse(e.response)
      const errorPayload =
        typeof errorData === 'object'
          ? errorData
          : { status: e.response.status, message: errorData }
      throw errorPayload
    }
    throw e
  }
}

export const get = async <T>(url: string, options?: Parameters<typeof api.get>[1]): Promise<T> =>
  handleResponse<T>(api.get(url, options))

export const post = async <T>(
  url: string,
  data: unknown,
  options?: Parameters<typeof api.post>[1],
): Promise<T> => handleResponse<T>(api.post(url, { json: data, ...options }))

export const put = async <T>(
  url: string,
  data: unknown,
  options?: Parameters<typeof api.put>[1],
): Promise<T> => handleResponse<T>(api.put(url, { json: data, ...options }))

export const del = async <T>(url: string, options?: Parameters<typeof api.delete>[1]): Promise<T> =>
  handleResponse<T>(api.delete(url, options))

export default api
