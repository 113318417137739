import { REFRESH_TOKEN_KEY } from '@/lib/react-query/keys'
import { generateRequestId } from '@/utils/id'

import { Token } from './auth'

const userPromisesCache = new Map<string, Promise<Token>>()

export const refreshToken = (refresh_token: string): Promise<Token> => {
  if (!userPromisesCache.has(REFRESH_TOKEN_KEY)) {
    const userPromise = fetch(`${import.meta.env.VITE_BACKEND_SERVICE}/v1/refresh-token`, {
      method: 'POST',
      headers: {
        'X-Request-Id': generateRequestId(),
      },
      body: JSON.stringify({
        refresh_token,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText)
        }
        return response.json() as Promise<Token>
      })
      .finally(() => {
        userPromisesCache.delete(REFRESH_TOKEN_KEY)
      })
    userPromisesCache.set(REFRESH_TOKEN_KEY, userPromise)
  }

  return userPromisesCache.get(REFRESH_TOKEN_KEY)!
}
