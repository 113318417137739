import { QueryClient } from '@tanstack/react-query'

const defaultOptions = {
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 minutes
      gcTime: 10 * 60 * 1000, // 10 minutes
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
}

let clientQueryClient: QueryClient | undefined

function createQueryClient() {
  return new QueryClient(defaultOptions)
}

function getClientQueryClient() {
  // if (typeof window === 'undefined') {
  //   // Server environment: always create a new client
  //   return createQueryClient()
  // }

  // Browser environment: create or reuse the client
  return (clientQueryClient ??= createQueryClient())
}

export const queryClient = getClientQueryClient()
