import { v4 as uuidv4 } from 'uuid'

import { randomString } from './string'

export function generateRequestId() {
  return randomString(8)
}

export function generateSessionId() {
  return uuidv4()
}
