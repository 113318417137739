import { useMutation } from '@tanstack/react-query'

import { post } from '@/lib/api'
import { useSession } from '@/lib/auth/useSession'

export function useLogOut() {
  const [session] = useSession()
  const mutation = useMutation({
    mutationFn: () =>
      post('v1/logout', {
        refresh_token: session?.refresh_token,
      }),
  })
  return mutation
}
