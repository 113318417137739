import * as React from 'react'

import { Navigate, Outlet } from 'react-router-dom'

import { useIsAuthentication } from './useIsAuthentication'

const AuthGuard: React.FC = () => {
  const isAuthenticated = useIsAuthentication()
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />
  }

  return <Outlet />
}

export default AuthGuard
