import { toast } from 'sonner'

export function toastError(e: Error) {
  try {
    const error = JSON.parse(e.message)
    toast.error(error.detail)
  } catch (error) {
    toast.error('Something went wrong')
  }
}
