import { useMutation } from '@tanstack/react-query'
import { LoaderCircleIcon } from 'lucide-react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { PasswordInput } from '@/components/ui/password-input'
import { post } from '@/lib/api'
import { auth } from '@/lib/auth'
import { SessionAuth } from '@/lib/auth/auth'
import { toastError } from '@/utils/error'

import { withRedirect } from './withRedirect'

function useLoginRequest() {
  const mutation = useMutation<SessionAuth, Error, { nickname: string; password: string }>({
    mutationFn: ({ nickname, password }) =>
      post('v1/login', {
        nick_name: nickname,
        password,
      }),
  })
  return mutation
}

type Inputs = {
  nickname: string
  password: string
}

function Login() {
  const mutation = useLoginRequest()
  const navigate = useNavigate()

  const { register, handleSubmit } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = ({ nickname, password }) => {
    mutation.mutate(
      { nickname, password },
      {
        onSuccess: (data: SessionAuth) => {
          auth.setSession(data)
          navigate('/kanban-board')
        },
        onError: toastError,
      },
    )
  }

  return (
    <div className="w-full h-dvh lg:grid lg:min-h-[600px] xl:min-h-[800px]">
      <div className="flex items-center justify-center py-12">
        <div className="mx-auto grid w-[380px] gap-6">
          <div className="grid gap-2">
            <h1 className="text-3xl font-bold">Login</h1>
            <p className="text-balance text-muted-foreground">
              Enter your nickname below to login to your account
            </p>
          </div>
          <form className="grid gap-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid gap-2">
              <Label htmlFor="nickname">Nickname</Label>
              <Input
                {...register('nickname', { required: true })}
                id="nickname"
                type="nickname"
                placeholder="Nickie"
                required
              />
            </div>
            <div className="grid gap-2">
              <div className="flex items-center">
                <Label htmlFor="password">Password</Label>
              </div>
              <div className="relative">
                <PasswordInput
                  {...register('password', { required: true })}
                  id="password"
                  fullWidth
                />
              </div>
            </div>
            <Button type="submit" className="w-full" disabled={mutation.isPending}>
              {mutation.isPending && (
                <LoaderCircleIcon width={16} height={16} className="mr-2 animate-spin" />
              )}
              Login
            </Button>
          </form>
        </div>
      </div>
      {/* <div className="hidden bg-muted lg:block">
        <img
          src="/placeholder.svg"
          alt="Image"
          width="1920"
          height="1080"
          className="h-full w-full object-cover dark:brightness-[0.2] dark:grayscale"
        />
      </div> */}
    </div>
  )
}
export default withRedirect(Login, '/kanban-board')
