// src/components/AuthGuard.tsx
import * as React from 'react'

import { Navigate } from 'react-router-dom'

import { useIsAuthentication } from '@/lib/auth/useIsAuthentication'

type WithRedirectProps = {
  [key: string]: unknown
}

export const withRedirect = <P extends WithRedirectProps>(
  WrappedComponent: React.ComponentType<P>,
  redirect: string,
) => {
  const WithRedirect: React.FC<P> = (props) => {
    const isAuthentication = useIsAuthentication()
    if (redirect && isAuthentication) {
      return <Navigate to={redirect} replace />
    }

    return <WrappedComponent {...props} />
  }

  WithRedirect.displayName = `WithRedirect(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`

  return WithRedirect
}
