import { cn } from '@/lib/utils'

export function LoadingSpinner({ className }: { className?: string }) {
  return (
    <div className={cn('w-full h-dvh flex items-center justify-center content-center', className)}>
      <div className="block animate-spin delay-300 duration-1000">
        <svg
          aria-label="Page spinner"
          className="text-[#767676]"
          height="40"
          role="img"
          viewBox="0 0 24 24"
          width="40"
          fill="currentColor"
        >
          <path d="M15 10.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m0 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m-6-6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m0 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3M12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24"></path>
        </svg>
      </div>
    </div>
  )
}
