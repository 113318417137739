import * as React from 'react'

import { EyeIcon, EyeOffIcon } from 'lucide-react'

import { cn } from '@/lib/utils'

import { Input } from './input'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  fullWidth?: boolean
}

const PasswordInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, fullWidth, ...props }, ref) => {
    const [showPass, setShowPass] = React.useState(false)

    return (
      <fieldset className={cn('inline-flex relative', fullWidth && 'w-full', className)}>
        <Input type={showPass ? 'text' : 'password'} ref={ref} className="pr-9" {...props} />
        <button
          className="absolute top-1/2 -translate-y-1/2 right-2 text-gray-600"
          aria-hidden={true}
          type="button"
          onClick={() => {
            setShowPass((value) => !value)
          }}
        >
          {showPass ? <EyeOffIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5" />}
        </button>
      </fieldset>
    )
  },
)
PasswordInput.displayName = 'PasswordInput'

export { PasswordInput }
